import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { ReactComponent as HostJaneLogo } from '../../svgs/icons/hostjane-logo.svg';
import { ReactComponent as ChevronDownIcon } from '../../svgs/icons/chevron-down.svg';
import { ReactComponent as ArrowRightIcon } from '../../svgs/icons/arrow-right.svg';
import { ReactComponent as SignInIcon } from '../../svgs/homenav/sign-in-alt-solid.svg';
import GenericIcon from '../GenericIcon';
import useWindowSize from '../../utils/useWindowSize';
import {
	useOpenGlobalModal,
	useIsGlobalModalActive,
} from '../../utils/globalModal';

function HoverArrowIcon() {
	return (
		<svg
			className="HoverArrow"
			width="10"
			height="10"
			viewBox="0 0 10 10"
			ariaHidden="true"
		>
			<g fillRule="evenodd" stroke="currentColor">
				<path className="HoverArrow__linePath" d="M0 5h7" />
				<path className="HoverArrow__tipPath" d="M1 1l4 4-4 4" />
			</g>
		</svg>
	);
}

export const NavSearchContainer = styled.div`
	display: flex;
	margin: 0;
	padding: 0;
	align-items: center;
	justify-content: center;
	font-size: ${props => props.theme.siteApp.navFontSize}px;

	.hostjane-nav-search__category {
		position: relative;
		flex: 0 0 auto;
		height: ${props => props.theme.siteApp.navSearch.height}px;
	}
	.hostjane-nav-search__catbutton {
		height: ${props => props.theme.siteApp.navSearch.height}px;
		border: 1px solid ${props => props.theme.siteApp.navSearch.borderColor};
		border-right: 0 none;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		background-color: #f3f3f3;
		outline: none;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 ${props => props.theme.gutter.small / 2}px;
		-webkit-appearance: none;

		@media screen and (max-width: 1368px) {
			max-width: 100px;
		}
		.hostjane-nav-search__catbuttonlabel {
			max-width: 130px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: pre;
			@media screen and (min-width: 1400px) {
				max-width: 180px;
			}
		}
	}
	.hostjane-nav-search__catbuttondd {
		margin-left: 10px;
		font-size: 12px;
	}
	.hostjane-nav-search__cat {
		position: absolute;
		opacity: 0;
		left: 0;
		width: 100%;
		top: 0;
		height: 100%;
	}

	.hostjane-nav-search__input {
		-webkit-appearance: none;
		appearance: none;
		border: 1px solid ${props => props.theme.siteApp.navSearch.borderColor};
		flex: 1 0 auto;
		border-right: 0 none;
		outline: none;
		border-radius: 0;
		color: ${props => props.theme.siteApp.navSearch.color};
		height: ${props => props.theme.siteApp.navSearch.height}px;
		line-height: ${props => props.theme.siteApp.navSearch.height}px;
		padding: ${props => props.theme.gutter.small / 4}px
			${props => props.theme.gutter.small / 2}px;
		transition: border-color ${props => props.theme.siteApp.transition};
		will-change: border-color;
		box-shadow: none;
		font-size: 17px;

		&::placeholder {
			font-size: 17px;
		}

		&:focus {
			border-color: ${props => props.theme.siteApp.primaryColor};
		}
	}

	.hostjane-nav-search__category + .hostjane-nav-search__input {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.hostjane-nav-search__submit {
		background-color: ${props => props.theme.siteApp.primaryColor};
		color: #fff;
		flex: 0 0 auto;
		border: 0 none;
		height: ${props => props.theme.siteApp.navSearch.height}px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 12px;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		transition: background-color ${props => props.theme.siteApp.transition};
		will-change: background-color;
		box-shadow: none;
		cursor: pointer;
		-webkit-appearance: none;

		&:hover {
			background-color: ${props => props.theme.siteApp.primaryHoverColor};
		}
	}
`;

export function NavSearch({
	showCategory = true,
	cats = [{ title: '', value: '' }],
}) {
	const [currentCat, setCurrentCat] = useState(cats[0].value);
	const openGlobalModal = useOpenGlobalModal();
	const isGlobalModalActive = useIsGlobalModalActive();

	return (
		<form
			action="https://www.hostjane.com/marketplace/search"
			method="GET"
			onSubmit={e => {
				if (isGlobalModalActive) {
					e.preventDefault();
					openGlobalModal(true);
				}
			}}
		>
			<NavSearchContainer className="hostjane-nav-search">
				{showCategory ? (
					<div className="hostjane-nav-search__category">
						<button
							className="hostjane-nav-search__catbutton"
							type="button"
						>
							<span className="hostjane-nav-search__catbuttonlabel">
								{cats.find(c => c.value === currentCat)
									?.title || 'Unknown'}
							</span>
							<GenericIcon
								className="hostjane-nav-search__catbuttondd"
								title="Select category"
							>
								<ChevronDownIcon />
							</GenericIcon>
						</button>
						<select
							className="hostjane-nav-search__cat"
							value={currentCat}
							onChange={e => setCurrentCat(e.target.value)}
							name="search_category"
						>
							{cats.map(cat => (
								<option value={cat.value} key={cat.value}>
									{cat.title}
								</option>
							))}
						</select>
					</div>
				) : (
					<input type="hidden" name="search_category" value="all" />
				)}
				<input
					className="hostjane-nav-search__input"
					defaultValue=""
					placeholder="Find Services"
					name="search_query"
					type="text"
					aria-label="Search HostJane Marketplace"
				/>
				<button type="submit" className="hostjane-nav-search__submit">
					Search
				</button>
			</NavSearchContainer>
		</form>
	);
}

export const NavContainer = styled.nav`
	display: flex;
	align-items: center;
	font-size: ${props => props.theme.siteApp.navFontSize}px;
	color: ${props => props.theme.siteApp.navColor};
`;

export const NavDirectLinkGroup = styled.div`
	display: flex;
	align-items: center;
	flex-flow: row wrap;
`;

export const NavDirectLink = styled.a`
cursor: pointer;
color: ${props => props.theme.siteApp.navAnchorColor};
text-decoration: none;
	flex: 0 0 auto;
	border: ${props => (props.noDecoration ? 'none' : '1px solid')};
	border-color: ${props =>
		props.highlighted ? props.theme.siteApp.navAnchorColor : 'transparent'};
		padding: 8px;
	border-radius: 4px;
	margin-right: ${props => props.theme.gutter.small / 4}px;
	background-color: transparent;
	transition: background-color ${props => props.theme.siteApp.transition},
		color ${props => props.theme.siteApp.transition};
		will-change: background-color, color;
		display: flex;
		align-items: center;
		justify-content: center;
		@media screen and (max-width: 1450px) {
			${props => props.dropOnSmallScreens && `display: none`};
		}


		${props => props.addMargin && `margin: 0px 10px !important;`}
	.hostjane-nav__linkicon {
		display: flex;
		align-items: center;
		justify-content: center;
		height: ${props => (props.noDecoration ? '1em' : '1.25em')};
		width: ${props => (props.noDecoration ? '1em' : '1.25em')};
		margin-right: '8px';
		svg {
			height: ${props => (props.noDecoration ? '1em' : '1.25em')};
		width: ${props => (props.noDecoration ? '1em' : '1.25em')};
			display: block;
		}
	}
	.hostjane-nav__linkdd {
		display: flex;
		width: 8px;
		height: 8px;
		border-top-width: 0;
		border-left-width: 0;
		border-bottom-width: 1px;
		border-right-width: 1px;
		border-style: solid;
		transform-origin: 75% 75%;
		transform: rotate(45deg);
		transition: transform 0.1s ease,
			border-color ${props => props.theme.siteApp.transition};
		will-change: border-color, transform;
		content: '';
		margin: -2px 2px 0 6px;
		border-color: #2c2c2c;
	}

	${props =>
		!props.noDecoration &&
		`&:hover,
	&.active {
		text-decoration: ${props.highlighted ? 'none' : 'underline'};
		background-color: ${
			props.highlighted
				? props.theme.siteApp.navAnchorColor
				: 'transparent'
		};
		color: ${
			props.highlighted
				? '#ffffff'
				: props.theme.siteApp.navAnchorHoverColor
		};
		.hostjane-nav__linkdd {
			border-color: #fff;
		}`}}
	&.active {
		.hostjane-nav__linkdd {
			transform: rotate(225deg);
			margin-top: -2px;
			margin-bottom: 2px;
			border-color: #fff;
		}
	}
	${props =>
		props.addHoverBackground &&
		`
		width: 80px;
		text-decoration: none;
		border-top-left-radius:24px;
		border-top-right-radius:24px;
		border-bottom-left-radius: 24px;
		border-bottom-right-radius: 24px;
		position: relative;

		&:before{
			content: '';
			background-color: #222222;
			transition: transform 200ms cubic-bezier(0.345, 0.115, 0.135, 1.42),opacity 150ms ease-out;
			box-shadow: 0 4px 20px rgba(34, 34, 34, 0.15);
			opacity: 0;
			content: "";
			border: inherit;
			border-radius: inherit;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
		}
		&:hover{
		text-decoration: none;

			&:before{
				opacity: 0.075;
			transform: scale(1) perspective(1px);
			}
		}
		@media screen and (max-width: 420px){
			width:24px;
			&:hover{
					&:before{
						opacity: 0;
					}
				}
		}
		`}

	&:last-of-type {
		margin-right: 0;
	}

	.sign-in-icon{
		svg{
			width: 0;
			height: 0;
		}
	}
	@media screen and (max-width: 360px) {
		.title {
			display: none;
		}
		.sign-in-icon {
			svg {
				width: 18px;
				height: 18px;
			}
		}
	}
`;

const NavDirectLinkWithChildrenContainer = styled.div`
	position: relative;
	@media screen and (max-width: 768px) {
		position: inherit;
	}
	@media screen and (max-width: 576px) {
		&.disable-mobile {
			display: none;
		}
	}
	${NavDirectLink} {
		padding-right: 10px;
		.hostjane-nav__linkicon {
			margin-right: ${props => (props.noDecoration ? '6px' : '4px')};
		}
	}

	.hostjane-nav-dlink__children_popup {
		position: absolute;
		width: calc(100vw - 20px);
		margin: 0 auto;
		left: 3px;
		top: 0;
		display: flex;
		justify-content: flex-end;
		pointer-events: none;
		perspective: 2000px;
		z-index: 10000;
		&:before {
			content: '';
			position: absolute;
			right: 0;
			left: 0;
			bottom: 0;
			top: -22px;
		}
		&.shown {
			pointer-events: auto;
			.hostjane-nav-dlink__children_container {
				transform: scale(1);
				opacity: 1;
				pointer-events: auto;
			}
		}
	}

	.hostjane-nav-dlink__children_container {
		display: block;
		background: #fff;
		box-shadow: 0px 15px 15px 0px rgba(50, 50, 93, 0.25),
			0px 0 60px 0px rgba(0, 0, 0, 0.3),
			0 -18px 60px 0px rgba(0, 0, 0, 0.025);
		border-radius: 4px;
		overflow: hidden;
		position: relative;
		font-size: 19px;
		transform-origin: 100% 0;
		will-change: transform, opacity;
		transition-property: transform, opacity;
		transition-duration: 0.25s;
		transform: scale(0.95);
		opacity: 0;
		pointer-events: none;
		flex: 1 0 100%;
		line-height: 40px;
		padding: ${props => (!props.noDecoration ? '20px 0' : '')};
	}

	.hostjane-nav-dlink__childclose {
		display: none;
		@media screen and (max-width: 576px) {
			display: block;
		}
		position: absolute;
		right: 0;
		top: 0;
		width: 51px;
		height: 51px;
		font-size: 0;
		cursor: pointer;
		transition: color 0.1s;
		text-decoration: none;
		-webkit-tap-highlight-color: transparent;
		color: #424770;
		border: 0 none;
		background: transparent;
		&::before,
		&::after {
			content: '';
			position: absolute;
			background: #0052cc;
			border-radius: 1px;
			left: 14px;
			right: 14px;
			top: 24px;
			height: 3px;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			-webkit-transition: background 0.1s;
			transition: background 0.1s;
		}
		&::after {
			transform: rotate(-45deg);
		}
		&:hover,
		&:focus,
		&:active {
			&::before,
			&::after {
				background: #0052cc;
			}
		}
	}

	.hostjane-nav-dlink__title {
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0.025em;
		color: #0a2540;
		text-transform: uppercase;
		margin: -5px 0 0 26px;
	}
	.hostjane-nav-dlink__childDiv {
		display: flex;
		align-items: center;
	}
	.hostjane-nav-dlink__childMain {
		display: flex;
		flex-direction: column;
	}
	.hostjane-nav-dlink__childlink {
		text-decoration: none;
		-webkit-tap-highlight-color: transparent;
		color: #424770;
		background: #fff;
		padding: 12px 30px;
		font-weight: 600;
		transition: background 200ms, color 200ms;
		will-change: background, color;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		.hostjane-nav-dlink__childdiv {
		}
		.hostjane-nav-dlink__childtitle {
			&.bottom-child {
				font-size: 14px;
				color: gray;
				line-height: initial;
				margin-bottom: 6px;
				padding-top: 5px;
				a {
					color: #5a5a5a;
					text-decoration: underline;
				}
			}
		}

		.hostjane-nav-dlink__childdesc {
			width: 100%;
			color: gray;
			font-size: 13px;
			line-height: 10px;
			&.bottom-child {
				padding-bottom: 5px;
				font-size: 14px;
				color: gray;
				a {
					color: #5a5a5a;
					text-decoration: underline;
				}
			}
		}

		.hostjane-nav-dlink__childicon,
		.hostjane-nav-dlink__childarrow {
			display: flex;
			height: 1.5em;
			width: 1.5em;
			margin-right: 8px;
			svg {
				height: 1.5em;
				width: 1.5em;
				display: block;
				width: auto;
			}
		}
		/* Sign In To remade icons turned grey to color */
		/*
		.hostjane-nav-dlink__childicon {
			svg path {
				fill: currentColor !important;
			}
		}
		*/
		.hostjane-nav-dlink__childarrow {
			margin-left: 10px;
			margin-right: 0;
			height: 10px;
			width: 10px;
			svg {
				height: 10px;
				width: 10px;
			}
		}

		&:first-of-type {
			color: #24292e;
			background: #f6f9fc;
		}
		&:hover {
			color: #6b7c93;
		}

		.HoverArrow {
			--arrowHoverTransition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
			--arrowHoverOffset: translateX(3px);
			position: relative;
			top: 1px;
			stroke-width: 2;
			fill: none !important;
			transition: opacity 0.15s;
			opacity: 1;

			.HoverArrow__linePath {
				opacity: 0;
				transition: opacity
					var(--hoverTransition, var(--arrowHoverTransition));
			}
			.HoverArrow__tipPath {
				transition: transform
					var(--hoverTransition, var(--arrowHoverTransition));
			}
		}

		&:hover {
			.HoverArrow__linePath {
				opacity: 1;
			}
			.HoverArrow__tipPath {
				transform: var(--arrowHoverOffset);
			}
		}
		&.border-top {
			margin-top: 12px;
			border-top: 0.5px solid #dee2e6;
		}

		&.bottom-child {
			margin-top: 12px;
			border-top: 0.5px solid #dee2e6;
			background-color: white;
			display: flex;
			// padding: 10px 0px;
		}
	}

	@media screen and (min-width: ${props =>
			props.theme.siteApp.breakpoints.tablet}px) {
		.hostjane-nav-dlink__children_popup {
			width: 300px;
			right: 0;
			left: auto;
			top: 45px;
		}
	}
`;

export function NavDirectLinkWithChildren({
	items,
	title,
	icon: Icon,
	highlighted,
	popupTitle,
	children = null,
	className = null,
	noDecoration = false,
	notShowOnMobile,
}) {
	const windowWidth = useWindowSize();
	const [shown, setShown] = useState(false);
	const ref = useRef(null);
	useEffect(() => {
		const handler = event => {
			if (
				ref.current &&
				(event.target.isEqualNode(ref.current) ||
					ref.current.contains(event.target))
			) {
				return;
			}
			setShown(false);
		};
		document.addEventListener('click', handler);
		return () => {
			document.removeEventListener('click', handler);
		};
	}, []);

	return (
		<NavDirectLinkWithChildrenContainer
			ref={ref}
			shown={shown}
			noDecoration={noDecoration}
			onClick={() => {
				setShown(!shown);
			}}
			onMouseOver={e => {
				e.preventDefault();
				if (windowWidth[0] > 1366) {
					setShown(true);
				}
			}}
			onMouseLeave={e => {
				e.preventDefault();
				if (windowWidth[0] >= 1366) {
					setShown(false);
				}
			}}
			onFocus={e => {
				e.preventDefault();
			}}
			className={notShowOnMobile ? 'disable-mobile' : undefined}
		>
			<NavDirectLink
				noDecoration={noDecoration}
				className={classNames(className, {
					active: shown,
				})}
				as="button"
				highlighted={highlighted}
			>
				{Icon ? (
					<span className="hostjane-nav__linkicon">
						<Icon />
					</span>
				) : null}
				<span className="title">{title}</span>
				<span className="sign-in-icon">
					{' '}
					<SignInIcon />
				</span>
				{!noDecoration && <span className="hostjane-nav__linkdd" />}
			</NavDirectLink>
			<div
				className={classNames('hostjane-nav-dlink__children_popup', {
					shown,
				})}
			>
				<div className="hostjane-nav-dlink__children_container">
					<button
						className="hostjane-nav-dlink__childclose"
						type="button"
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							setShown(false);
						}}
					>
						Close
					</button>
					{children || (
						<>
							<h4 className="hostjane-nav-dlink__title">
								{popupTitle}
							</h4>
							{items.map((item, index) => {
								if (!item.bottomChild) {
									return (
										<a
											href={item.href}
											className={classNames(
												'hostjane-nav-dlink__childlink',
												item.borderTop && 'border-top'
											)}
											// eslint-disable-next-line react/no-array-index-key
											key={`${index}-${item.href}`}
										>
											{/* <div className="hostjane-nav-dlink__childdiv"> */}

											<div className="hostjane-nav-dlink__childDiv">
												{' '}
												{item.icon ? (
													<span className="hostjane-nav-dlink__childicon">
														<item.icon />
													</span>
												) : null}
												<span className="hostjane-nav-dlink__childtitle">
													{item.title}
												</span>
												<span className="hostjane-nav-dlink__childarrow">
													<HoverArrowIcon />
												</span>
											</div>

											{/* </div> */}
											{item.description && (
												<span className="hostjane-nav-dlink__childdesc">
													{item.description}
												</span>
											)}
										</a>
									);
								}
								return (
									<div
										className="hostjane-nav-dlink__childlink bottom-child"
										// eslint-disable-next-line react/no-array-index-key
										key={`${index}-${item.href}`}
									>
										<span
											className="hostjane-nav-dlink__childtitle bottom-child"
											dangerouslySetInnerHTML={{
												__html: item.title,
											}}
										/>
										<span
											className="hostjane-nav-dlink__childdesc bottom-child"
											dangerouslySetInnerHTML={{
												__html: item.description,
											}}
										/>
									</div>
								);
							})}
						</>
					)}
				</div>
			</div>
		</NavDirectLinkWithChildrenContainer>
	);
}

export const HostJaneLogoContainer = styled.div`
	width: ${props => props.width}px;
	svg {
		width: ${props => props.width}px;
		height: auto;
		display: block;
	}
`;
export function HostJaneLogoLink({ to = '/', width = 225, className = null }) {
	return (
		<HostJaneLogoContainer width={width} className={className}>
			<Link to={to} className="hostjane-logo__link">
				<HostJaneLogo className="hostjane-logo__svg" />
			</Link>
		</HostJaneLogoContainer>
	);
}

export const NavDivider = styled.div`
	width: 1px;
	height: ${props => props.height || '100%'};
	background-color: ${props => props.theme.siteApp.navSearch.borderColor};
	margin: 0 15px;
`;
